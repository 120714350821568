import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

const TagPosts = ({ pageContext, data }) => {
  const { tag } = pageContext
  const { edges, totalCount } = data.allMarkdownRemark
  const tagHeader = `${totalCount} post${
    totalCount === 1 ? "" : "s"
  } tagged with "${tag}"`

  return (
    <Layout>
      <Seo title={`Posts tagged with ${tag}`} />
      <h2 className="text-4xl font-bold mb-6 border-b pb-4 text-blue-800">{tagHeader}</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {edges.map(({ node }) => {
          const { slug } = node.fields
          const { title, date } = node.frontmatter
          return (
            <div key={slug} className="bg-white shadow-md rounded-lg overflow-hidden border-2 border-blue-200 transition-all duration-300 hover:shadow-lg hover:border-blue-400">
              <div className="p-6">
                <Link to={slug} className="text-blue-700 hover:text-blue-900">
                  <h3 className="text-2xl font-bold mb-2">{title}</h3>
                </Link>
                <small className="text-blue-600 block mb-4">Published on {date}</small>
                <p className="text-gray-700 mb-4">{node.excerpt}</p>
                <Link to={slug} className="inline-block bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition-colors duration-300">
                  Read More
                </Link>
              </div>
            </div>
          )
        })}
      </div>
      <div className="mt-8">
        <Link to="/tags" className="text-blue-600 hover:text-blue-800">← Back to all tags</Link>
      </div>
    </Layout>
  )
}

export default TagPosts

export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMMM Do, YYYY")
          }
          excerpt(pruneLength: 100)
        }
      }
    }
  }
`
